import { useRouter } from "next/router";
import { FunctionComponent, useEffect } from "react";

import { useUser } from "./firebase";

import LoginDialog from "@/components/login/loginDialog";
import { createUrlWithReturnToCurrent } from "@/utils/urlHelper";

type Props = {
  redirect?: string;
  children: React.ReactNode;
};

const AuthProvider: FunctionComponent<Props> = ({
  children,
  redirect,
}: Props) => {
  const router = useRouter();
  const { user, isLogin } = useUser();

  useEffect(() => {
    if (isLogin) {
      if (!user.hasUserData) {
        const url = createUrlWithReturnToCurrent(router, "/user/register");
        router.replace(url);
      } else if (redirect) {
        router.replace(redirect);
      }
    }
  }, []);

  // ログインしていない場合はログインダイアログを表示する
  if (!isLogin) {
    return <LoginDialog />;
  }

  return <>{children}</>;
};

export default AuthProvider;
