import { NextRouter } from "next/router";
import { UrlObject } from "url";

export const createUrlWithExistingReturnTo = (
  router: NextRouter,
  pathname: string,
): UrlObject => {
  const url: UrlObject = { pathname };
  const returnTo = router.query.returnTo;
  if (typeof returnTo === "string" && returnTo !== "") {
    url.query = { returnTo };
  }
  return url;
};

export const createUrlWithReturnToCurrent = (
  router: NextRouter,
  pathname: string,
): UrlObject => {
  const url: UrlObject = { pathname, query: { returnTo: router.asPath } };
  return url;
};

export const getReturnTo = (
  router: NextRouter,
  defaultPath: string,
): string => {
  const returnTo = router.query.returnTo;
  if (typeof returnTo === "string" && returnTo !== "") {
    return returnTo;
  }
  return defaultPath;
};
