import AuthProvider from "@/components/authProvider";
import { LoadingView } from "@/components/layout/loading";

// ログインが確認できたらチャット一覧に飛ばす
export default function Home() {
  return (
    <AuthProvider redirect="/chat">
      <LoadingView />
    </AuthProvider>
  );
}
