import { useRouter } from "next/router";
import { Fragment, FunctionComponent, useState } from "react";

import { createUrlWithReturnToCurrent } from "@/utils/urlHelper";
import { Dialog, Transition } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";

type Props = {
  customBodyText?: string;
  customButtonText?: string;
};

const LoginDialog: FunctionComponent<Props> = (
  { customBodyText, customButtonText },
  Props,
) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    //setIsOpen(false);
  }

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center"></div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex items-center">
                    <Image
                      src={"/logo_384.png"}
                      width={70}
                      height={70}
                      alt="logo_chatfax"
                      className="mr-4 rounded-md ring-1 ring-gray-300"
                    />
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Sapon
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-700">
                          {customBodyText
                            ? customBodyText
                            : "チャットのように簡単にファックスできるサービス"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-center">
                    <div className="mt-6">
                      <button
                        type="button"
                        className="btn btn-primary px-16"
                        onClick={() => {
                          closeModal();
                          if (router.pathname !== "/") {
                            router.push(
                              createUrlWithReturnToCurrent(router, "/login"),
                            );
                            return;
                          }
                          router.push("/login");
                        }}
                      >
                        {customButtonText
                          ? customButtonText
                          : "会員登録・ログインする"}
                      </button>
                    </div>
                  </div>
                  <div className="mx-auto mb-5 mt-4 flex w-full items-center px-4 sm:max-w-sm">
                    <p className="text-xs text-gray-400">
                      [会員登録・ログインする]をクリックすると、
                      <Link href="./termsOfService" className="underline">
                        「Sapon利用規約」
                      </Link>
                      および
                      <Link href="./privacyPolicy" className="underline">
                        「プライバシーポリシー」
                      </Link>
                      に同意したものとみなされます。
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default LoginDialog;
